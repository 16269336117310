/* eslint-disable no-param-reassign */

import type { AttributeColormapAssociationMap } from '@local/webviz/dist/components/Properties/PropertiesDialog';
import type { SettingsVisualizationOverlays } from '@local/webviz/dist/components/Settings/Settings.types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { initialSelectionState, initialState } from './selectors';
import type {
    SelectObject,
    TreeItemChildrenPayload,
    TreeStructure,
    VisualizationState,
} from './visualizationSlice.types';

export const visualizationSlice = createSlice({
    name: 'visualization',
    initialState,
    reducers: {
        selectObjectScenePanel(
            state: VisualizationState,
            { payload }: PayloadAction<SelectObject>,
        ) {
            const { objectId } = payload;
            state.scenePanel.selected.selection = [objectId];
            state.scenePanel.selected.shiftSelection = [];
            state.scenePanel.selected.lastSelected = objectId;
        },
        multiSelectObjectScenePanel(
            state: VisualizationState,
            { payload }: PayloadAction<SelectObject>,
        ) {
            const { objectId, shift } = payload;
            const selection = state.scenePanel.selected.selection.includes(objectId)
                ? [...state.scenePanel.selected.selection]
                : [...state.scenePanel.selected.selection, objectId];

            const lastSelected = shift ? state.scenePanel.selected.lastSelected : objectId;
            const shiftSelection = shift
                ? [...state.scenePanel.selected.shiftSelection, objectId]
                : state.scenePanel.selected.shiftSelection;

            state.scenePanel.selected.selection = selection;
            state.scenePanel.selected.shiftSelection = shiftSelection;
            state.scenePanel.selected.lastSelected = lastSelected;
        },
        unselectObjectScenePanel(
            state: VisualizationState,
            { payload }: PayloadAction<SelectObject>,
        ) {
            const { objectId } = payload;
            if (!state.scenePanel.selected.selection.includes(objectId)) {
                return;
            }
            const selection = state.scenePanel.selected.selection.filter((id) => id !== objectId);

            state.scenePanel.selected.selection = selection;
            state.scenePanel.selected.lastSelected = objectId;
        },
        clearSelectedObjectsScenePanel(state: VisualizationState) {
            state.scenePanel.selected = initialSelectionState;
        },
        selectObjectProjectTree(
            state: VisualizationState,
            { payload }: PayloadAction<SelectObject>,
        ) {
            const { objectId } = payload;
            state.projectTree.selected.selection = [objectId];
            state.projectTree.selected.shiftSelection = [];
            state.projectTree.selected.lastSelected = objectId;
        },
        multiSelectObjectProjectTree(
            state: VisualizationState,
            { payload }: PayloadAction<SelectObject>,
        ) {
            const { objectId, shift } = payload;
            const selection = state.projectTree.selected.selection.includes(objectId)
                ? [...state.projectTree.selected.selection]
                : [...state.projectTree.selected.selection, objectId];

            const lastSelected = shift ? state.projectTree.selected.lastSelected : objectId;
            const shiftSelection = shift
                ? [...state.projectTree.selected.shiftSelection, objectId]
                : state.projectTree.selected.shiftSelection;

            state.projectTree.selected.selection = selection;
            state.projectTree.selected.shiftSelection = shiftSelection;
            state.projectTree.selected.lastSelected = lastSelected;
        },
        unselectObjectProjectTree(
            state: VisualizationState,
            { payload }: PayloadAction<SelectObject>,
        ) {
            const { objectId } = payload;
            if (!state.projectTree.selected.selection.includes(objectId)) {
                return;
            }
            const selection = state.projectTree.selected.selection.filter((id) => id !== objectId);

            state.projectTree.selected.selection = selection;
            state.projectTree.selected.lastSelected = objectId;
        },
        clearSelectedObjectsProjectTree(state: VisualizationState) {
            state.projectTree.selected = initialSelectionState;
        },

        addToLoadedObjects(state: VisualizationState, action: PayloadAction<TreeStructure>) {
            state.loadedObjects[action.payload.treeId] = action.payload;
        },
        removeFromLoadedObjects(
            state: VisualizationState,
            { payload: objectId }: PayloadAction<string>,
        ) {
            if (state.loadedObjects[objectId]) {
                delete state.loadedObjects[objectId];
            }
        },
        updateOverlays(
            state: VisualizationState,
            { payload }: PayloadAction<Partial<SettingsVisualizationOverlays>>,
        ) {
            state.overlays = { ...state.overlays, ...payload };
        },
        clearSceneState(state: VisualizationState) {
            state.loadedObjects = {};
            state.projectTree.selected = initialSelectionState;
            state.scenePanel.selected = initialSelectionState;
        },
        addTreeItem(state: VisualizationState, { payload }: PayloadAction<TreeStructure>) {
            if (!payload.treeId) {
                return;
            }
            state.objectTree.treeState[payload.treeId] = payload;
        },
        addTreeItems(state: VisualizationState, { payload }: PayloadAction<TreeStructure[]>) {
            payload.forEach((treeItem) => {
                if (!treeItem.treeId) {
                    return;
                }
                state.objectTree.treeState[treeItem.treeId] = treeItem;
            });
        },
        updateTree(
            state: VisualizationState,
            { payload }: PayloadAction<{ treeDictionary: { [key: string]: TreeStructure } }>,
        ) {
            const { treeDictionary } = payload;
            Object.entries(treeDictionary).forEach(([treeId, treeItem]) => {
                state.objectTree.treeState[treeId] = treeItem;
            });
        },
        addTreeItemChildren(
            state: VisualizationState,
            { payload }: PayloadAction<TreeItemChildrenPayload>,
        ) {
            if (!state.objectTree.treeState[payload.treeId]) {
                return;
            }
            const currentChildren = state.objectTree.treeState[payload.treeId].children ?? [];
            state.objectTree.treeState[payload.treeId].children = [
                ...currentChildren,
                ...payload.children.filter((child) => !currentChildren.includes(child)),
            ];
        },
        addTreeItemsChildren(
            state: VisualizationState,
            { payload }: PayloadAction<TreeItemChildrenPayload[]>,
        ) {
            payload.forEach(({ treeId, children }) => {
                if (!state.objectTree.treeState[treeId]) {
                    return;
                }
                const currentChildren = state.objectTree.treeState[treeId].children || [];
                state.objectTree.treeState[treeId].children = [
                    ...currentChildren,
                    ...children.filter((child) => !currentChildren.includes(child)),
                ];
            });
        },
        expandTreeItem(state: VisualizationState, { payload }: PayloadAction<string>) {
            state.objectTree.expanded[payload] = true;
        },
        collapseTreeItem(state: VisualizationState, { payload }: PayloadAction<string>) {
            state.objectTree.expanded[payload] = false;
        },
        updateObjectAttributeColormapAssociations(
            state: VisualizationState,
            {
                payload,
            }: PayloadAction<{
                objectId: string;
                attributeAssociationMap: AttributeColormapAssociationMap;
            }>,
        ) {
            const { objectId, attributeAssociationMap } = payload;
            state.objectAttributeColormapAssociation[objectId] = {
                ...state.objectAttributeColormapAssociation[objectId],
                ...attributeAssociationMap,
            };
        },
    },
});

export const {
    selectObjectScenePanel,
    multiSelectObjectScenePanel,
    unselectObjectScenePanel,
    clearSelectedObjectsScenePanel,
    selectObjectProjectTree,
    multiSelectObjectProjectTree,
    unselectObjectProjectTree,
    clearSelectedObjectsProjectTree,
    addToLoadedObjects,
    removeFromLoadedObjects,
    updateOverlays,
    addTreeItem,
    addTreeItems,
    addTreeItemChildren,
    addTreeItemsChildren,
    expandTreeItem,
    collapseTreeItem,
    clearSceneState,
    updateTree,
    updateObjectAttributeColormapAssociations,
} = visualizationSlice.actions;
