import type { ListedObject } from '@api/goose/dist/enhancedGooseClient';

export interface ListedObjectDisplay extends ListedObject {
    depth: number;
}

export const isListedObjectDisplay = (object: ListedObjectDisplay) => 'created_at' in object;

// Goose SubPath objects do not contain a uuid, which we need to provide a unique key for each list item.
// Additionally, we will append a depth object, so that we can track the depth of traversal within the UI.
export const processGooseContents = (depth: number, contents?: ListedObject[]) => {
    if (!contents) {
        return [];
    }

    return contents.map(
        (object: ListedObject) =>
            ({
                ...object,
                depth,
            }) as ListedObjectDisplay,
    );
};

export const fileNameExtensionRemover = (name: string | undefined) => {
    if (!name) {
        return '';
    }
    return name.replace('.json', '');
};
