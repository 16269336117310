import type { OrgListedObject } from '@api/goose/dist/enhancedGooseClient';
import * as L from 'leaflet';
import { renderToString } from 'react-dom/server';
import { Marker } from 'react-leaflet';

import { useStyles } from './Clustering.styles';
import { ObjectIcon } from './ObjectIcon';

const DEFAULT_OFFSET = 10000;

interface ObjectIconMarkerProps {
    active?: boolean;
    hovered?: boolean;
    zIndexOffset?: number;
    orgObject: OrgListedObject;
    onClick?: (objectID: string) => void;
}

export function ObjectIconMarker({
    orgObject,
    active = false,
    hovered = false,
    onClick = () => null,
    zIndexOffset = DEFAULT_OFFSET,
}: ObjectIconMarkerProps) {
    const { classes } = useStyles();

    const bboxLatLong: L.LatLngTuple[] | null =
        orgObject.geojson_bounding_box?.coordinates[0].map((lngLat) => [lngLat[1], lngLat[0]]) ??
        null;

    if (!bboxLatLong) {
        return null;
    }
    const latLongBounds = new L.LatLngBounds(bboxLatLong);

    const objectMarkerMetadata = {
        name: orgObject.name,
        schema: orgObject.schema,
        object_id: orgObject.object_id,
    };

    return (
        <Marker
            {...objectMarkerMetadata}
            zIndexOffset={zIndexOffset}
            position={latLongBounds.getCenter()}
            icon={L.divIcon({
                className: 'custom',
                iconSize: undefined,
                html: renderToString(
                    <div className={classes.objectIconMarkerBase}>
                        <ObjectIcon
                            active={active}
                            hovered={hovered}
                            schema={orgObject.schema}
                            objectID={orgObject.object_id}
                            name={orgObject.name.substring(0, orgObject.name.length - 5)}
                        />
                    </div>,
                ),
            })}
            eventHandlers={{
                mousedown: () => onClick(orgObject.object_id),
            }}
        />
    );
}
