import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../store';

const getColormapCollection = 'getColormapCollection';
const getAssociationCollection = 'getAssociationCollection';

const colormapState = (state: RootState) => state.colormap;

export function getColormapLoadingStatuses(objectId: string) {
    return createSelector(colormapState, (stateRoot) => {
        let colormapStatus;
        let associationStatus;
        Object.values(stateRoot.queries).forEach((value) => {
            if (value && typeof value === 'object' && 'endpointName' in value) {
                if (value.endpointName === getColormapCollection) {
                    colormapStatus = value.status;
                } else if (
                    value.endpointName === getAssociationCollection &&
                    value.originalArgs &&
                    (value.originalArgs as { objectId: string }).objectId === objectId
                ) {
                    associationStatus = value.status;
                }
            }
        });
        const colormapLoadingFailed =
            colormapStatus === 'rejected' || associationStatus === 'rejected';
        const colormapLoadingPending =
            colormapStatus === 'pending' || associationStatus === 'pending';

        return { colormapLoadingFailed, colormapLoadingPending };
    });
}
