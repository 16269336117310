import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const styles = (theme: Theme) =>
    ({
        root: {
            pointerEvents: 'none',
            margin: theme.spacing(1, 0, 0, 0),
            position: 'absolute',
        },
        tools: {
            background: theme.palette.common.white,
            pointerEvents: 'auto',
            width: 'auto',
            padding: theme.spacing(0.125),
        },
        divider: {
            height: '50%',
            alignContent: 'center',
            margin: theme.spacing(0, 2),
        },
        resetButton: {
            height: theme.spacing(5),
            marginRight: theme.spacing(),
        },
    }) as const;

export const useStyles = makeStyles()(styles);
