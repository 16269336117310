import type { Theme } from '@mui/material/styles/createTheme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
    formGroup: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        columnGap: theme.spacing(0.5),
        rowGap: 0,
    },
    container: {
        overflow: 'auto',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        height: 156,
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
}));
