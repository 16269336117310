import type { OrgListedObject } from '@api/goose/dist/enhancedGooseClient';

import { useSearchInteractionContext } from 'src/contexts/SearchInteractionContext';

import { ObjectIconMarker } from './map/clustering/ObjectIconMarker';
import { ObjectBounds } from './map/ObjectBounds';

interface HoveredObjectViewProps {
    ignoredObjects?: string[];
    objects: OrgListedObject[];
}

export function HoveredObjectView({ objects, ignoredObjects = [] }: HoveredObjectViewProps) {
    const { hoveredObject } = useSearchInteractionContext();
    const hoveredOrgObject = objects.find((val) => val.object_id === hoveredObject);
    const ignore = ignoredObjects.includes(hoveredOrgObject?.object_id || '');

    return (
        hoveredOrgObject && (
            <>
                <ObjectBounds object={hoveredOrgObject} />
                {!ignore && <ObjectIconMarker orgObject={hoveredOrgObject} hovered />}
            </>
        )
    );
}
