import { createBentleyAccessTokenBaseQuery } from '@local/login/dist/apiClients/identityClient/requests/BentleyAccessTokenApi';
import { getUrlConfig } from '@local/login/dist/util/getUrlConfig';
import { createApi } from '@reduxjs/toolkit/query/react';

export const fileClient = createApi({
    reducerPath: 'file',
    baseQuery: createBentleyAccessTokenBaseQuery(() => getUrlConfig(false, true)),
    tagTypes: ['File', 'file'],
    endpoints: () => ({}),
});
