import { Delete } from '@local/web-design-system-2/dist/icons';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import { CLEAR_DRAWN_LAYERS } from '../../../../strings';
import { useStyles } from './Control.styles';

interface Props {
    onClear: () => void;
}

export function ClearBoundingBoxButton({ onClear }: Props) {
    const map = useMap();
    const { classes } = useStyles();
    const [disabled, setDisabled] = useState(true);

    const onclick = (e: any) => {
        e.stopPropagation();
        map.pm.getGeomanDrawLayers().forEach((layer) => {
            map.removeLayer(layer);
        });
        onClear();
    };

    useEffect(() => {
        // Called when a shape is drawn/finished.
        map.on('pm:create', () => {
            const activeDrawLayers = map.pm.getGeomanDrawLayers();
            setDisabled(activeDrawLayers.length === 0);
        });

        // Called when any leaflet layer is removed.
        map.on('layerremove', () => {
            const activeDrawLayers = map.pm.getGeomanDrawLayers();
            setDisabled(activeDrawLayers.length === 0);
        });
    }, [map]);

    return (
        <Button
            variant="contained"
            disabled={disabled}
            title={CLEAR_DRAWN_LAYERS}
            aria-disabled={disabled}
            aria-label={CLEAR_DRAWN_LAYERS}
            classes={{ root: classes.drawingButton }}
            sx={{
                '&.Mui-disabled': {
                    color: '#39393C',
                    backgroundColor: '#1D1F23',
                },
            }}
        >
            <Grid
                gap={1}
                container
                wrap="nowrap"
                direction="row"
                onClick={onclick}
                alignItems="center"
                justifyContent="center"
            >
                <Delete className={classes.buttonIcon} />
                <Typography className={classes.drawingText}>Clear</Typography>
            </Grid>
        </Button>
    );
}
