import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    versionHistory: {
        display: 'flex',
        gap: theme.spacing(1),
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    versionHistoryList: {
        overflowX: 'hidden',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    versionHistoryItem: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '75px',
        gap: theme.spacing(2),
        justifyContent: 'space-between',
        marginBottom: '20px',
        overflow: 'hidden',
    },
    version: {
        position: 'relative',
        marginTop: theme.spacing(2),
        '&:before': {
            backgroundColor: theme.palette.grey[300],
            content: '""',
            marginLeft: '-0.2em',
            position: 'absolute',
            top: '2.2em',
            left: '0.90em',
            width: '0.13em',
            height: '75%',
        },
        '&:last-of-type': {
            minHeight: theme.spacing(5),
            '&:before': {
                display: 'none',
            },
        },
    },
}));
