import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    contentTitle: {
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.primary.main,
    },
    noObjects: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(14),
    },
    noObjectsImage: {
        margin: 'auto',
        width: '50%',
    },
    hoveringTable: {
        position: 'relative',
        overflow: 'auto',
        padding: theme.spacing(3),
        minHeight: '78vh',
    },
    pagination: {
        padding: 0,
    },
}));
