import { checkIsAdmin } from '@local/login';
import { WORKSPACES_OVERVIEW_PAGE } from '@local/workspaces/dist/urls';
import { fetchWorkSpaceData } from '@local/workspaces/src/apiClients/workspaceClientEndpoints';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import { Link as RouterLink, useParams } from 'react-router-dom';

import {
    WEBVIZ_VIEWER_BREADCRUMB,
    WORKSPACES_BREADCRUMB,
    workspacesPath,
} from 'src/utils/breadcrumbs';

export const ViewerBreadcrumb = () => {
    const params = useParams();
    const isAdmin = checkIsAdmin();
    const theme = useTheme();
    const { data: workspace, isLoading } = fetchWorkSpaceData(params, isAdmin);

    if (isLoading) {
        return <Skeleton variant="rectangular" width="300px" height="14px" />;
    }
    // TODO: This is very temporary, this whole component should be deleted and handled inside of contentArea nicely.
    return (
        <Grid container alignItems="flex-start" direction="row" wrap="nowrap">
            <Grid item>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link
                        component={RouterLink}
                        variant="body2"
                        underline="none"
                        color="inherit"
                        to={workspacesPath(params)}
                    >
                        {WORKSPACES_BREADCRUMB}
                    </Link>
                    <Link
                        component={RouterLink}
                        variant="body2"
                        underline="none"
                        color="inherit"
                        to={`${workspacesPath(params)}/${params.hubCode}/${
                            params.workspaceUuid
                        }/${WORKSPACES_OVERVIEW_PAGE}`}
                    >
                        {workspace?.name ?? ''}
                    </Link>
                    <Typography variant="body2" color={theme.palette.text.primary} noWrap>
                        {WEBVIZ_VIEWER_BREADCRUMB}
                    </Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
    );
};
