import { BackButton } from '@local/content-area/dist/TitleRow/buttons/BackButton';
import { TitleRow } from '@local/content-area/dist/TitleRow/TitleRow';
import { WORKSPACES_LIST_PAGE } from '@local/workspaces/dist/urls';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { GenericNotFoundPage } from 'src/pages/notFoundPage/GenericNotFoundPage';
import {
    BACK_TO_WORKSPACES,
    WORKSPACE_NOT_FOUND_MESSAGE,
    WORKSPACE_NOT_FOUND_TITLE,
} from 'src/strings';
import { workspaceListingWithLinkBreadcrumb } from 'src/utils/breadcrumbs';

import { useStyles } from './WorkspaceNotFound.styles';

export const NotFound = () => {
    const { classes } = useStyles();
    const params = useParams();
    const theme = useTheme();

    const segments = workspaceListingWithLinkBreadcrumb(params).map(({ name, path }) => (
        <Link
            component={RouterLink}
            to={path ?? ''}
            sx={{
                textDecoration: 'none',
                color: theme.palette.grey[700],
                fontSize: 12,
                lineHeight: '14px',
                letterSpacing: '0.13px',
            }}
        >
            {name}
        </Link>
    ));

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">{segments}</Breadcrumbs>
            <TitleRow
                title=""
                actions={
                    <div className={classes.button}>
                        <BackButton
                            text={BACK_TO_WORKSPACES}
                            destination={`/${params.orgUuid}/${WORKSPACES_LIST_PAGE}`}
                        />
                    </div>
                }
            />
            <GenericNotFoundPage
                title={WORKSPACE_NOT_FOUND_TITLE}
                message={WORKSPACE_NOT_FOUND_MESSAGE}
            />
        </>
    );
};
