import {
    DateTimeCell,
    SizeCell,
    StringCell,
} from '@local/web-design-system-2/dist/components/GenericListing/ListCells';
import type { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import { defaultStringSort } from '@local/workspaces/dist/utils/Sorting';

import {
    FILES_HEADER_NAME,
    FILES_HEADER_DATA_TYPE,
    FILES_HEADER_FILE_SIZE,
    FILES_HEADER_MODIFIED_BY,
    FILES_HEADER_LAST_MODIFIED,
} from 'src/strings';

import { DataTypeCell } from '../workspaceFilesPage/fieldDefinitions/DataTypeCell';
import { UserCell } from './workspaceContent/UserCell';

export const fileDefinition: FieldDefinition[] = [
    {
        key: 'name',
        label: FILES_HEADER_NAME,
        component: StringCell,
        sortFunction: defaultStringSort,
    } as FieldDefinition<string>,
    {
        key: 'data_type',
        label: FILES_HEADER_DATA_TYPE,
        component: DataTypeCell,
    } as FieldDefinition<string>,
    {
        key: 'size',
        label: FILES_HEADER_FILE_SIZE,
        component: SizeCell,
        sortFunction: defaultStringSort,
    } as FieldDefinition<string>,
    {
        key: 'modified_by',
        label: FILES_HEADER_MODIFIED_BY,
        component: UserCell,
        sortFunction: defaultStringSort,
    } as FieldDefinition<string>,
    {
        key: 'modified_at',
        label: FILES_HEADER_LAST_MODIFIED,
        component: DateTimeCell,
        sortFunction: defaultStringSort,
    } as FieldDefinition<string>,
];
