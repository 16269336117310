import type { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme, _params, classes: any) => ({
    controlContainer: {
        display: 'flex',
        gap: '16px',
        flexDirection: 'column',
        padding: theme.spacing(0.75),
    },
    control: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fill: '#E2E0E0',
        width: 32,
        height: 32,
        backgroundColor: '#282a2e',
        borderRadius: '4px',

        '&:hover': {
            background: 'rgba(255, 255, 255, 0.8)',
            borderColor: theme.palette.grey[400],
            boxShadow: '0px 0px 6px 0px #00000026',
            fill: theme.palette.primary.main,
        },
    },
    controlLabel: {
        display: 'flex',
    },
    drawingButton: {
        width: '82px',
        height: '32px',
        color: '#E2E0E0',
        pointerEvents: 'auto',
        backgroundColor: '#282A2E',
        borderRadius: '4px !important',

        '&:hover': {
            backgroundColor: '#0F1012',
            boxShadow: '0px 0px 6px 0px #00000026',
            color: '#FFFFFF',
        },
    },
    drawingText: {
        fontFamily: 'Inter',
        fontSize: '12.5px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '14px',
        letterSpacing: '0.2px',
    },
    buttonIcon: {
        width: '20px',
        height: '20px',
    },
    layerControlBase: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#EBEBEB',
        borderRadius: '8px',
        backgroundColor: '#1d1f23',
        padding: '4px',
    },
    layerControlHover: {
        maxWidth: 'unset',
        maxHeight: 'unset',
    },
    layersIcon: {
        width: '16px',
        height: '16px',
    },
    layerRow: {
        gap: '8px',
        cursor: 'pointer',
        alignItems: 'center',
        padding: '8px',
        borderRadius: '4px',

        '&:hover': {
            backgroundColor: '#3f4144',
        },
    },
    checkboxRoot: {
        width: '14px',
        height: '14px',
        padding: '0px',
        backgroundColor: '#303030',
        border: '1px solid #979797',
        borderRadius: '4px !important',

        '&:hover': {
            border: '1px solid #5a5d69 !important',
            backgroundColor: '#313444 !important',
        },
    },
    checkboxChecked: {
        backgroundColor: '#234b66 !important',
        border: '1px solid white !important',

        '&:hover': {
            border: '1px solid white !important',
            backgroundColor: '#0f1012 !important',
        },
    },
    checkboxIcon: {
        color: 'white',
        width: '8px',
        height: '8px',
    },
    controlButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#E2E0E0',
        width: 32,
        height: 32,
        backgroundColor: '#282A2E',
        borderRadius: '4px !important',

        '&:hover': {
            backgroundColor: '#0F1012',
            boxShadow: '0px 0px 6px 0px #00000026',
            color: '#FFFFFF',

            [`.${classes.controlIcon}`]: {
                color: '#FFFFFF',
            },
        },
    },
    layerButtonActive: {
        backgroundColor: '#0F1012',
        boxShadow: '0px 0px 6px 0px #00000026',
        color: '#FFFFFF',

        [`.${classes.controlIcon}`]: {
            color: '#FFFFFF',
        },
    },
    controlIcon: {
        width: '16px',
        height: '16px',
        color: '#E2E0E0',
    },
    iconDisabled: {
        fill: '#39393C',
    },
    layerRowText: {
        fontFamily: 'Inter',
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '14px',
    },
    popper: {
        zIndex: 1000,
        paddingRight: '4px',
    },
}));
