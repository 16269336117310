import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import { useStyles } from './LoadingSkeleton.styles';

export const LoadingSkeleton = () => {
    const { classes } = useStyles();

    return (
        <Grid className={classes.skeleton} automation-id="loading-skeleton">
            <Skeleton variant="rectangular" width="100%" />
            <Skeleton variant="rectangular" width="100%" />
            <Skeleton variant="rectangular" width="100%" />
        </Grid>
    );
};
