import type { DockablePosition } from '@local/web-design-system/dist/components/DockableContainer';

import type { AttributeTypes } from '../constants';

export interface DockableDialogProps extends DockablePosition {
    onClose: () => void;
}
export enum DockedWindows {
    Slice = 'slice',
    Measure = 'measure',
    Settings = 'settings',
    Properties = 'properties',
    SelectionPanel = 'selectionPanel',
}

export interface ObjectAttributeType {
    name: string;
    values: { data: string };
    attribute_type: AttributeTypes;
    key?: string;
}
