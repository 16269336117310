import type { ListCellComponentProps } from '@local/web-design-system-2/dist/components/GenericListing/types';
import Typography from '@mui/material/Typography';

import { getFileExtension } from 'src/utils/fileUtils';

export const DataTypeCell = ({ item }: ListCellComponentProps) => (
    <Typography variant="body2" textTransform="uppercase">
        {getFileExtension(item.name)}
    </Typography>
);
