import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import type { SxProps, Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import type { ReactNode } from 'react';

export interface PaginatedListProps {
    isLoading?: boolean;
    isError?: boolean;
    isEmpty?: boolean;
    headersOnEmptyMessage?: boolean;
    emptyMessage?: ReactNode | string;
    errorMessage?: ReactNode | string;
    preHeader?: ReactNode;
    header?: ReactNode;
    postHeader?: ReactNode;
    content: ReactNode;
    stickyHeader?: boolean;
    sx?: SxProps<Theme>;
    preHeaderSx?: SxProps<Theme>;
    tableBodySx?: SxProps<Theme>;
    containerSx?: SxProps<Theme>;
}

export interface EmptyListMessageProps {
    message: ReactNode | string;
}

export interface ErrorListMessageProps {
    message: ReactNode | string;
}

const DisplayMessage = ({
    message,
    isError = false,
}: {
    message: string | ReactNode;
    isError?: boolean;
}) =>
    typeof message === 'string' ? (
        <Grid container alignItems="center" justifyContent="center">
            <Grid item>
                <Typography variant="h4" color={isError ? 'error' : 'info'}>
                    {message}
                </Typography>
            </Grid>
        </Grid>
    ) : (
        message
    );

export const LoadingOverlay = () => {
    const theme = useTheme();
    return (
        <Grid
            container
            sx={{
                alignContent: 'stretch',
                position: 'absolute' as const,
                height: '100%',
                width: '100%',
                backgroundColor: theme.palette.grey[100],
                opacity: 0.7,
                zIndex: 999,
            }}
        >
            <CircularProgress />
        </Grid>
    );
};

export const isContentEmpty = (content: ReactNode) =>
    !content || (Array.isArray(content) && (content as []).length === 0);

export function PaginatedList(props: PaginatedListProps) {
    const {
        isLoading = false,
        isError = false,
        isEmpty = false,
        errorMessage = '',
        emptyMessage = '',
        headersOnEmptyMessage = false,
        stickyHeader = false,
        header,
        content,
        preHeader,
        postHeader,
        sx,
        preHeaderSx,
        tableBodySx,
        containerSx,
    } = props;
    const reloading = isLoading && !isContentEmpty(content);
    const initLoading = isLoading && !reloading;

    if (initLoading) {
        return <CircularProgress />;
    }
    if (isError) {
        return <DisplayMessage message={errorMessage} isError />;
    }
    if (isEmpty && !headersOnEmptyMessage) {
        return <DisplayMessage message={emptyMessage} />;
    }
    return (
        <Stack direction="column" sx={{ width: '100%', ...containerSx }}>
            {preHeader ? (
                <Grid item sx={{ ...preHeaderSx }}>
                    {preHeader}
                </Grid>
            ) : null}
            <TableContainer sx={{ height: '100%' }}>
                {reloading ? <LoadingOverlay /> : null}
                <Table stickyHeader={stickyHeader} sx={{ ...sx }}>
                    {header}
                    <TableBody sx={{ ...tableBodySx }}>
                        {postHeader}
                        {content}
                    </TableBody>
                </Table>
                {isEmpty && headersOnEmptyMessage && <DisplayMessage message={emptyMessage} />}
            </TableContainer>
        </Stack>
    );
}
