import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useRef, useState } from 'react';

/**
 * A tooltip that can only be shown if the child element is overflowing.
 */
export function OverflowTooltip({ children, ...leftover }: TooltipProps) {
    const childRef = useRef(null);
    const [canShowTooltip, setCanShowTooltip] = useState(false);

    const child = React.Children.only(children) as React.ReactElement;
    const reffedChild = React.cloneElement(child, { ...child.props, ref: childRef });

    useEffect(() => {
        if (childRef.current) {
            const typography = childRef.current as HTMLElement;
            setCanShowTooltip(
                typography.scrollWidth > typography.clientWidth ||
                    typography.scrollHeight > typography.clientHeight,
            );
        }
    }, [childRef.current, children]);

    return canShowTooltip ? <Tooltip {...leftover}>{reffedChild}</Tooltip> : reffedChild;
}
