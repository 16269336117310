import type { GetObjectResponse } from '@api/goose/dist/enhancedGooseClient';
import { getConfig } from '@local/app-config';
import { trackUserAction } from '@local/metrics/dist/src/metrics';
import { WORKSPACES_LIST_PAGE } from '@local/workspaces/src/urls';
import type { LDFlagSet } from 'launchdarkly-js-sdk-common';
import type { NavigateFunction } from 'react-router-dom';

import { ENV_DEVELOPMENT } from 'src/constants';
import { UserAction } from 'src/metrics.types';
import { ID_PARAM } from 'src/strings';
import { WEBVIZ_VIEWER } from 'src/urls';

import { isSchemaBlockSyncViewable, isSchemaDriverViewable } from './extractSchema';

const DRIVER_HOST_PREFIX = 'driver';
const DRIVER_DEV_HOST_PREFIX = 'varify-qa.driver.dev';
const BLOCKMODEL_PATH = 'blockmodel';
const BLOCKSYNC_DEV_HOST_PREFIX = BLOCKMODEL_PATH;
const BLOCKSYNC_HOST_PREFIX = 'blocksync';

export function createNavigateToViewer(
    navigate: NavigateFunction,
    hubCode: string,
    workspaceUuid: string,
    id: string,
) {
    // Higher order function which returns a callable function to navigate to Viewer for a given object id.
    return () => {
        trackUserAction(UserAction.USER_CLICKED_VIEWER_BUTTON);
        navigate(
            `/${WORKSPACES_LIST_PAGE}/${hubCode}/${workspaceUuid}/${WEBVIZ_VIEWER}?${ID_PARAM}=${id}`,
        );
    };
}

export function createNavigateToDriver(
    hostname: string,
    orgUuid: string,
    hubCode: string,
    workspaceUuid: string,
    id: string,
) {
    return () => {
        // Higher order function which returns a callable function to navigate to Driver for a given object id.
        window.open(
            `https://${buildDriverUrl(
                hostname,
            )}/${orgUuid}/${WORKSPACES_LIST_PAGE}/${hubCode}/${workspaceUuid}?${ID_PARAM}=${id}`,
            '_blank',
        );
    };
}

export function createNavigateToBlockSync(
    hostname: string,
    orgUuid: string,
    workspaceUuid: string,
    hubCode: string,
    blocksyncUuid: string,
) {
    return () => {
        // Higher order function which returns a callable function to navigate to BlockSync for a given object id.
        window.open(
            `https://${buildBlockSyncUrl(
                hostname,
            )}/${orgUuid}/${hubCode}/${workspaceUuid}/${BLOCKMODEL_PATH}/${blocksyncUuid}`,
            '_blank',
        );
    };
}

export function buildDriverUrl(hostname: string) {
    return buildBaseUrl(hostname, DRIVER_DEV_HOST_PREFIX, DRIVER_HOST_PREFIX);
}

export function buildBlockSyncUrl(hostname: string) {
    return buildBaseUrl(hostname, BLOCKSYNC_DEV_HOST_PREFIX, BLOCKSYNC_HOST_PREFIX);
}

function buildBaseUrl(hostname: string, devHostPrefix: string, standardHostPrefix: string) {
    const appConfig = getConfig();
    const hostParts = hostname.split('.');
    const hostPrefix =
        appConfig?.environment === ENV_DEVELOPMENT ? devHostPrefix : standardHostPrefix;
    const baseUrl = hostPrefix.concat(`.${hostParts.slice(1).join('.')}`);
    return baseUrl;
}

export function canOpenInDriver(objectSchema: string, featureFlags: LDFlagSet) {
    return featureFlags.evouiOpenInDriver && isSchemaDriverViewable(objectSchema);
}

export function canOpenInBlockSync(
    objectDetail: GetObjectResponse | undefined,
    featureFlags: LDFlagSet,
) {
    if (!objectDetail) {
        return false;
    }

    return (
        canOpenSchemaInBlockSync(objectDetail.object.schema, featureFlags) &&
        objectDetail.object.blocksync_uuid
    );
}

export function canOpenSchemaInBlockSync(objectSchema: string, featureFlags: LDFlagSet) {
    return featureFlags.evouiOpenInBlocksync && isSchemaBlockSyncViewable(objectSchema);
}
