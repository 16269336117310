import Typography from '@mui/material/Typography';
import type * as L from 'leaflet';

import { useStyles } from './Clustering.styles';

interface ClusterIconProps {
    cluster: L.MarkerCluster;
    renderObjectIconGroup?: (objectIDs: string[], coords?: L.LatLngExpression) => void;
}

// TODO (future): We should find a less hacky approach to do this.
// This class is just to make typescript happy, not solve any problems.
// We are using protected methods from the leaflet cluster, which we should try avoid.
// Given our current time constraints, we will go with this approach and fix later.
/* eslint-disable no-underscore-dangle */
interface TypedCluster {
    _map: L.Map;
    _animationSpiderfy: () => void;
    _animationUnspiderfy: () => void;
}

export function ClusterIcon({ cluster, renderObjectIconGroup }: ClusterIconProps) {
    const { classes } = useStyles();
    const typedCluster = cluster as any as TypedCluster;

    if (!typedCluster?._map) {
        throw new Error(
            'Leaflet map unavailable, it should be there. Did the non-public API change?',
        );
    }

    if (!typedCluster._animationSpiderfy || !typedCluster._animationUnspiderfy) {
        throw new Error(
            'Leaflet clustering library has changed its non public API. Rendering object groups will be broken.',
        );
    }

    // Hijack the spiderfy and unspiderfy animations to call the group render function.
    typedCluster._animationSpiderfy = () => {
        cluster.setOpacity(0);
        renderObjectIconGroup?.(
            cluster.getAllChildMarkers().map((marker: any) => marker.options.object_id),
            cluster.getLatLng(),
        );
    };

    typedCluster._animationUnspiderfy = () => {
        cluster.setOpacity(1);
        renderObjectIconGroup?.([]);
    };

    return (
        <div className={classes.clusterOuter}>
            <div className={classes.clusterBase}>
                <Typography variant="h3">{cluster.getChildCount()}</Typography>
            </div>
        </div>
    );
}
