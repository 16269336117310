import { useGetObjectByIdQuery } from '@api/goose/dist/enhancedGooseClient';
import { extractObjectSchemaName } from '@api/goose/dist/utils/extractObjectSchemaName';
import { trackUserAction } from '@local/metrics/dist/src/metrics';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BaseSidebar } from 'src/components/sidebar/BaseSidebar';
import { usePersistedState } from 'src/hooks/usePersistedState';
import type { RootState } from 'src/store/store';
import { OBJECT_NOT_FOUND_TITLE } from 'src/strings';

import { Categories, UserAction } from '../../../../metrics.types';
import { Empty } from './Empty';
import { Properties } from './Properties';
import { VersionHistory } from './VersionHistory';

interface Props {
    isFile: boolean;
    workspaceId: string;
    fileFolderUUID?: string;
}

export const Sidebar = ({ isFile, workspaceId, fileFolderUUID = undefined }: Props) => {
    const [folderID] = usePersistedState('folderID');
    const params = useParams();

    const filterProperties = useSelector((state: RootState) => state.foldersFilters.foldersFilter);

    const selectedFilter = filterProperties?.find((element) => element.id === folderID);
    const skip = !fileFolderUUID || !isFile;

    const {
        data: gooseData,
        isFetching,
        isError,
    } = useGetObjectByIdQuery(
        {
            objectId: fileFolderUUID ?? '',
            orgId: getOrgUuidFromParams(params),
            workspaceId,
            includeVersions: true,
        },
        {
            skip,
        },
    );

    useEffect(() => {
        if (gooseData) {
            trackUserAction(UserAction.GOOSE_OBJECT_CLICKED, {
                label: `User opened properties on type: ${extractObjectSchemaName(
                    gooseData?.object?.schema,
                )}`,
                category: Categories.WORKSPACE_CONTENT_PAGE,
            });
        }
    }, [gooseData]);

    if (!fileFolderUUID) {
        return <BaseSidebar isFetching={false} properties={<Empty />} versionHistory={null} />;
    }

    if ((isError || (!isFetching && !gooseData)) && isFile) {
        return (
            <Stack alignItems="center" width="100%" spacing={2} py={2}>
                <Box width="220px">
                    <NotFoundSvg />
                </Box>
                <Typography variant="body2">{OBJECT_NOT_FOUND_TITLE}</Typography>
            </Stack>
        );
    }

    return (
        <BaseSidebar
            isFetching={isFetching}
            properties={
                <Properties isFile={isFile} data={gooseData} selectedFilter={selectedFilter} />
            }
            versionHistory={isFile && <VersionHistory data={gooseData} />}
        />
    );
};
