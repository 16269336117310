import type { Cartesian3, Scene } from '@cesium/engine';
import { Cartesian2, Cartographic, Math as CesiumMath, SceneTransforms } from '@cesium/engine';

export function findBoxCenter(coords: number[][]): { lng: number; lat: number } {
    const minLon = coords[0][0];
    const maxLon = coords[1][0];
    const minLat = coords[0][1];
    const maxLat = coords[2][1];

    return {
        lng: (minLon + maxLon) / 2,
        lat: (minLat + maxLat) / 2,
    };
}

export interface ViewBounds {
    ne: { lng: number; lat: number };
    nw: { lng: number; lat: number };
    se: { lng: number; lat: number };
    sw: { lng: number; lat: number };
}

export const getViewBounds = (cesiumWidget: any): ViewBounds | null => {
    if (!cesiumWidget) return null;

    const { camera } = cesiumWidget;
    const { canvas } = cesiumWidget;

    const corners = [
        new Cartesian2(0, 0),
        new Cartesian2(canvas.width, 0),
        new Cartesian2(0, canvas.height),
        new Cartesian2(canvas.width, canvas.height),
    ];

    const results = corners.map((corner) => {
        const cartesian = camera.pickEllipsoid(corner);
        if (cartesian) {
            const cartographic = Cartographic.fromCartesian(cartesian);
            return {
                lng: CesiumMath.toDegrees(cartographic.longitude),
                lat: CesiumMath.toDegrees(cartographic.latitude),
            };
        }

        return null;
    });

    if (results.some((result) => result === null)) return null;

    return {
        nw: results[0]!,
        ne: results[1]!,
        se: results[3]!,
        sw: results[2]!,
    };
};

export const arePositionsClose = (
    position1: Cartesian3,
    position2: Cartesian3,
    scene: Scene,
    pixelDistance: number,
): Boolean => {
    if (!position1 || !position2) return false;

    const pos1 = SceneTransforms.worldToWindowCoordinates(scene, position1, new Cartesian2());
    const pos2 = SceneTransforms.worldToWindowCoordinates(scene, position2, new Cartesian2());

    if (!pos1 || !pos2) return false;

    const distance = Math.sqrt((pos1.x - pos2.x) ** 2 + (pos1.y - pos2.y) ** 2);

    return distance < pixelDistance;
};
