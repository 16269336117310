import { makeStyles } from 'tss-react/mui';

export const FOOTER_HEIGHT_UNITS = 2.5;
export const useStyles = makeStyles()((theme) => ({
    appLogo: {
        width: theme.spacing(5.25),
        height: theme.spacing(5.25),
    },
    appLogoLong: {
        height: theme.spacing(8),
        width: theme.spacing(24),
    },
    footer: {
        background: theme.palette.grey[100],
        color: theme.palette.grey[400],
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'flex-end',
        fontSize: '12px',
        height: theme.spacing(FOOTER_HEIGHT_UNITS),
        marginBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2),
        position: 'absolute',
        bottom: 0,
        width: `calc(100vw - ${theme.spacing(2)})`,
    },
    contentInner: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        height: '100%',
        overflow: 'hidden',
    },
    webVizContent: {
        display: 'grid',
        height: '100%',
        padding: 0,
    },
}));
