import type { CesiumWidget } from '@cesium/engine';
import { CesiumMap } from '@local/map-viewer/dist/cesium/CesiumMap';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';

import { ObjectPoint } from './cesium/Points/ObjectPoint';

export const CesiumSearchPage = () => {
    setDocumentTitle('Search');

    return (
        <CesiumMap
            customComponents={(cesiumWidget: CesiumWidget) => [
                <ObjectPoint cesiumWidget={cesiumWidget} />,
            ]}
        />
    );
};
