import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    noSearchResults: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(14),
        width: '50%',
        margin: 'auto',
    },
    affinityFolderRowOverrides: {
        height: theme.spacing(7),
    },
    table: {
        maxHeight: '71vh',
    },
}));
