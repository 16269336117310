import type { Theme } from '@mui/material/styles';

import { UNIT } from './constants';
import { getSpacing } from './getSpacing';
import { palette } from './palette';

const triangleBorder = {
    bottom: '100%',
    border: 'solid transparent',
    content: '" "',
    height: 0,
    width: 0,
    position: 'absolute' as const,
};

function thinScrollBarBaseStyles(theme: Theme) {
    return {
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.grey[100],
            borderRadius: theme.spacing(1.25),
            '--webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.0)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.grey[300],
            borderRadius: theme.spacing(1.25),
            '--webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme.palette.grey[500],
        },
    };
}

export const common = (theme: Theme) => ({
    disabledRow: {
        backgroundColor: theme.palette.grey[50],
        opacity: 0.6,
    },
    topArrow: {
        '&:before': {
            ...triangleBorder,
            borderColor: 'rgba(0, 0, 0, 0)',
            borderBottomColor: palette.border.main,
            borderWidth: getSpacing(theme, 1) + 1,
            marginLeft: -(getSpacing(theme, 1) + 1),
            left: theme.spacing(3),
        },
        '&:after': {
            ...triangleBorder,
            borderColor: 'rgba(255, 255, 255, 0)',
            borderBottomColor: theme.palette.common.white,
            borderWidth: theme.spacing(),
            marginLeft: theme.spacing(-1),
            left: theme.spacing(3),
        },
    },
    thinScrollBar: {
        scrollbarWidth: 'thin' as const,
        overflowY: 'auto' as const,
        '&::-webkit-scrollbar': {
            width: '0.4rem',
            height: '0.4rem',
        },
        ...thinScrollBarBaseStyles(theme),
    },
    thinScrollBarHorizontal: {
        scrollbarHeight: 'thin',
        scrollbarWidth: 'thin' as const,
        overflowX: 'auto' as const,
        '&::-webkit-scrollbar': {
            height: '0.6rem',
        },
        ...thinScrollBarBaseStyles(theme),
    },
    overlayBox: {
        backgroundColor: 'white',
        boxShadow: '0 0 6px 0 rgb(0 0 0 / 10%)',
        border: `1px solid ${theme.palette.grey[300]}`,
    },
    hoveringTable: {
        backgroundColor: theme.palette.common.white,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
});

export const spacing = (factor: number) => UNIT * factor;
