import type { WorkspaceAffinitySchemaResponse } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { http, HttpResponse } from 'msw';
import { setupWorker } from 'msw/browser';

import { objectTypeAffinitySchema, timeBasedAffinitySchema } from './mockAffinitySchema';

const baseURL = 'https://workspace.dev-sqnt.com/workspace';

function getAffinitySchema200Response(): WorkspaceAffinitySchemaResponse {
    return {
        links: {
            self_link: `${baseURL}/workspace/orgs/:orgId/workspaces/:workspaceId/affinity-schema`,
        },
        schemas: [objectTypeAffinitySchema, timeBasedAffinitySchema],
    };
}

export const handlers = [
    http.get(`${baseURL}/workspace/orgs/:orgId/workspaces/:workspaceId/affinity-schema`, () =>
        HttpResponse.json(getAffinitySchema200Response(), { status: 200 }),
    ),
];

export const startWorker = () => {
    const worker = setupWorker(...handlers);
    worker.start({
        onUnhandledRequest: 'bypass',
    });
};
