import Overflow from '@local/web-design-system-2/dist/icons/Overflow';
import User from '@local/web-design-system-2/dist/icons/User';
import { MenuItemWithIcon } from '@local/web-design-system/dist/components/Menu/Menu';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { usePersistedState } from 'src/hooks/usePersistedState';

export const AffinityFolderRowActions = ({ folderId }: { folderId: string }) => {
    const [, setSearchParams] = useSearchParams();
    const [, setFolderID] = usePersistedState('folderID');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton size="large" onClick={handleClick} automation-id="overflow-button">
                <Overflow fontSize="small" />
            </IconButton>
            <Menu
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                disableAutoFocusItem
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <MenuItem
                    onClick={() => {
                        setFolderID(folderId);
                        setSearchParams({ propertiesSidebar: 'open' });
                    }}
                    automation-id="edit-users-workspace-overflow-menu-item"
                >
                    <MenuItemWithIcon IconElement={User} label="Properties" />
                </MenuItem>
            </Menu>
        </>
    );
};
