import Grid from '@mui/material/Grid/Grid';
import Stack from '@mui/material/Stack';

import { LoadingSkeleton } from './LoadingSkeleton';
import { useStyles } from './Sidebar.styles';

interface Props {
    isFetching: boolean;
    properties: React.ReactNode;
    versionHistory: React.ReactNode;
}

/**
 * An abstract sidebar, which can be extended and added to a list view. See workspaces "Overview" and "Files"
 * tabs for example implementations.
 */
export const BaseSidebar = ({ isFetching, properties, versionHistory }: Props) => {
    const { classes } = useStyles();

    return (
        <Grid item automation-id="side-bar" className={classes.container}>
            {isFetching ? (
                <LoadingSkeleton />
            ) : (
                <Stack spacing={6}>
                    {properties}
                    {versionHistory}
                </Stack>
            )}
        </Grid>
    );
};
