import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    container: {
        gap: theme.spacing(2),
        justifyContent: 'center',
    },
    emptyHeader: {
        marginBottom: 0,
    },
    text: {
        textAlign: 'center',
    },
    dragAndDropContainer: {
        height: '100%',
    },
    highlight: {
        height: '100%',
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        border: `1px solid ${theme.palette.primary.main}`,
        animationName: 'outlineChange',
        animationDuration: '1s',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
    },
}));
