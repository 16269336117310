import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { fileUploadStatus } from 'src/store/slices/fileUploadStatus';
import type { RootState } from 'src/store/store';
import { useAppDispatch } from 'src/store/store';
import type { FileStatus } from 'src/types/files';
import { UploadStatus } from 'src/types/files';

interface Props {
    workspaceId: string;
}

/**
 * Manages state for whether the file upload container is open or not. Built to hide Redux from components.
 */
export const useFileUploadContainer = ({ workspaceId }: Props) => {
    const dispatch = useAppDispatch();

    const fileUploads = useSelector((state: RootState) => state.uploadStatus[workspaceId]) ?? [];

    const [uploadedCount, failedCount, completedCount, uploadingCount] = useMemo(() => {
        const uploaded = fileUploads.reduce(
            (count: number, file: FileStatus) =>
                file.uploadStatus === UploadStatus.Uploaded ? count + 1 : count,
            0,
        );
        const failed = fileUploads.reduce(
            (count: number, file: FileStatus) =>
                file.uploadStatus === UploadStatus.Failed ? count + 1 : count,
            0,
        );
        const uploading = fileUploads.reduce(
            (count: number, file: FileStatus) =>
                file.uploadStatus === UploadStatus.Uploading ? count + 1 : count,
            0,
        );
        const completed = uploaded + failed;

        return [uploaded, failed, completed, uploading];
    }, [fileUploads]);

    const {
        actions: { clearUploadStatus, setFileUploadStatus },
    } = fileUploadStatus;

    const clearFileUploads = () => {
        dispatch(clearUploadStatus({ workspaceId }));
    };

    const upsertFileStatus = (fileStatus: FileStatus) => {
        dispatch(setFileUploadStatus({ workspaceId, ...fileStatus }));
    };

    return {
        isOpen: fileUploads.length > 0,
        fileUploads,
        uploadingCount,
        uploadedCount,
        failedCount,
        completedCount,
        clearFileUploads,
        upsertFileStatus,
    };
};
