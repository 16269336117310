import DefaultObject from '@local/web-design-system-2/dist/icons/DefaultObject';
import DesignModelRendered from '@local/web-design-system-2/dist/icons/DesignModelRendered';
import Drillhole from '@local/web-design-system-2/dist/icons/Drillhole';
import GeologicalModel from '@local/web-design-system-2/dist/icons/GeologicalModel';
import Grid2D from '@local/web-design-system-2/dist/icons/Grid2D';
import Lineations from '@local/web-design-system-2/dist/icons/Lineations';
import LineSegments from '@local/web-design-system-2/dist/icons/LineSegments';
import MergedPointset from '@local/web-design-system-2/dist/icons/MergedPointset';
import Topography from '@local/web-design-system-2/dist/icons/Topography';
import { LocationsIcon } from '@local/web-design-system/dist/icons/Artifacts/LocationsIcon';
import { MeshIcon } from '@local/web-design-system/dist/icons/Artifacts/MeshIcon';
import { TensorGridBlocktreeIcon } from '@local/web-design-system/dist/icons/Artifacts/TensorGridBlocktreeIcon';
import { TransformValuesIcon } from '@local/web-design-system/dist/icons/Artifacts/TransformValuesIcon';
import { VariogramIcon } from '@local/web-design-system/dist/icons/Artifacts/VariogramIcon';

import { extractSchema } from 'src/utils/extractSchema';
import { Schemas } from 'src/visualization/constants';

// TODO: Change imports once UI/UX have them designed and landed CENPLAT-24980

export const SchemaIcon = (schema?: string) => {
    const iconsDictionary = {
        [Schemas.DesignGeometrySchema]: DesignModelRendered,
        [Schemas.DownholeCollectionSchema]: Drillhole,
        [Schemas.DownholeIntervalsSchema]: Drillhole,
        [Schemas.ExploratoryLocationSchema]: LocationsIcon,
        [Schemas.GeologicalModelMeshesSchema]: GeologicalModel,
        [Schemas.GeophysicalRecords1DSchema]: GeologicalModel,
        [Schemas.GlobalEllipsoidSchema]: Topography,
        [Schemas.IntervalDownholesSchema]: Drillhole,
        [Schemas.LineSegmentsSchema]: LineSegments,
        [Schemas.LineationsDataPointsetSchema]: Lineations,
        [Schemas.LocalEllipsoidsSchema]: Topography,
        [Schemas.NonParametricContinuousCumulativeDistributionSchema]: TransformValuesIcon,
        [Schemas.PlanarDataPointsetSchema]: MergedPointset,
        [Schemas.PointsetSchema]: MergedPointset,
        [Schemas.Regular2DGridSchema]: Grid2D,
        [Schemas.Regular3DGridSchema]: Grid2D,
        [Schemas.RegularMasked3DGridSchema]: Grid2D,
        [Schemas.Tensor2DGridSchema]: TensorGridBlocktreeIcon,
        [Schemas.Tensor3DGridSchema]: TensorGridBlocktreeIcon,
        [Schemas.TriangleMeshSchema]: MeshIcon,
        [Schemas.UnstructuredGridSchema]: Grid2D,
        [Schemas.UnstructuredHexGridSchema]: Grid2D,
        [Schemas.UnstructuredQuadGridSchema]: Grid2D,
        [Schemas.UnstructuredTetGridSchema]: Grid2D,
        [Schemas.VariogramSchema]: VariogramIcon,
    };

    const gooseType = schema && extractSchema(schema);
    if (gooseType && gooseType in iconsDictionary) {
        return iconsDictionary[gooseType as keyof typeof iconsDictionary];
    }

    return DefaultObject;
};
