import { azureMapSearchClient } from '@api/azure/dist/mapSearchApi';
import { XidApiClient } from '@api/azure/dist/mapTokenApi';
import { colormapClientEndpoints } from '@api/colormap/dist/enhancedColormapClient';
import { gooseClient } from '@api/goose/dist/enhancedGooseClient';
import { visualizationClient } from '@api/visualization/dist/visualizationClient';
import { middleware as loginMiddleware, reducers as loginReducers } from '@local/login';
import { termsOfUseMiddleware, termsOfUseReducers } from '@local/terms-of-use';
import { manageClientSeequent, middleware as manageMiddleware } from '@local/user-manage/dist';
import { manageClientBentley } from '@local/user-manage/dist/apiClients/manageClient/manageClientBentley';
import { genericClient } from '@local/workspaces/dist/apiClients/genericApi/genericClient';
import { workspaceClient } from '@local/workspaces/dist/apiClients/workspaceClient';
import type { Reducer } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { fileClient } from 'src/apiClients/fileClient';
import { displaySettingsSlice } from 'src/store/features/displaySettingSlice';

import { fileUploadStatus } from './slices/fileUploadStatus';
import { folderFilterSlice } from './slices/folderFilterSlice';
import { visualizationSlice } from './visualization/visualizationSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [displaySettingsSlice.name],
};

const combinedReducer = combineReducers({
    [workspaceClient.reducerPath]: workspaceClient.reducer,
    [fileClient.reducerPath]: fileClient.reducer,
    [gooseClient.reducerPath]: gooseClient.reducer,
    [genericClient.reducerPath]: genericClient.reducer,
    [manageClientSeequent.reducerPath]: manageClientSeequent.reducer, // leave here until it's gone from user-manage
    [manageClientBentley.reducerPath]: manageClientBentley.reducer,
    [XidApiClient.reducerPath]: XidApiClient.reducer,
    [azureMapSearchClient.reducerPath]: azureMapSearchClient.reducer,
    [visualizationClient.reducerPath]: visualizationClient.reducer,
    [folderFilterSlice.name]: folderFilterSlice.reducer,
    [fileUploadStatus.name]: fileUploadStatus.reducer,
    [displaySettingsSlice.name]: displaySettingsSlice.reducer,
    [visualizationSlice.name]: visualizationSlice.reducer,
    ...loginReducers,
    ...termsOfUseReducers,
    [colormapClientEndpoints.reducerPath]: colormapClientEndpoints.reducer,
});

const rootReducer: Reducer<RootState> = (state, action) => {
    if (action.type === 'store/reset') {
        return {} as RootState;
    }
    return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const createStore = () =>
    configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }).concat([
                workspaceClient.middleware,
                fileClient.middleware,
                visualizationClient.middleware,
                gooseClient.middleware,
                genericClient.middleware,
                XidApiClient.middleware,
                azureMapSearchClient.middleware,
                ...manageMiddleware,
                ...loginMiddleware,
                ...termsOfUseMiddleware,
                colormapClientEndpoints.middleware,
            ]),
    });

export const store = createStore();

// Infer the `RootState` and `AppDispatch` types from combinedReducer
export type RootState = ReturnType<typeof combinedReducer>;

persistStore(store);

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
