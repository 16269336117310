import { getCurrentEvoInstance } from '@local/login';
import { createBentleyAccessTokenBaseQuery } from '@local/login/dist/apiClients/identityClient/requests/BentleyAccessTokenApi';
import type { FetchArgs } from '@reduxjs/toolkit/dist/query/react';
import { createApi } from '@reduxjs/toolkit/query/react';
import omit from 'lodash-es/omit';

/** The type from GENERATED_workspaceClientEndpoints for filter params is an object like: filter: {name: 'Rocks', created_by: 'John' }
 * RTKQuery expects params to look like: ['filter[name]': 'Rocks', 'filter[created_by]': 'John']
 * Without this transformation we get [Object object] in the request URL, which doesn't work
 */
export function transformFilterParams(args: FetchArgs): FetchArgs {
    const newFilters: Record<string, any> = {};

    if (args?.params?.filter) {
        for (const [key, value] of Object.entries(args?.params?.filter)) {
            newFilters[`filter[${key}]`] = value;
        }

        return {
            ...args,
            params: {
                ...omit(args.params, 'filter'),
                ...newFilters,
            },
        };
    }

    return args;
}

function getUrlConfig() {
    const { org, hub } = getCurrentEvoInstance();
    const url = `${hub?.url}/workspace/orgs/${org?.id}/workspaces`;

    return {
        url,
        forceOauthPrefix: false,
        includeClientSecret: true,
    };
}

export const workspaceClient = createApi({
    tagTypes: ['Workspace', 'Image'],
    reducerPath: 'workspace',
    baseQuery: createBentleyAccessTokenBaseQuery(getUrlConfig, transformFilterParams),
    endpoints: () => ({}),
});
