/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable consistent-return */
import type { CesiumWidget } from '@cesium/engine';
import {
    BillboardCollection,
    Cartesian2,
    Cartesian3,
    Color,
    HorizontalOrigin,
    LabelCollection,
    LabelStyle,
    PointPrimitiveCollection,
    VerticalOrigin,
} from '@cesium/engine';
import throttle from 'lodash-es/throttle';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import imagePart from 'src/assets/object.png';
import { useDiscoverContext } from 'src/contexts/DiscoverContext';

import type { ViewBounds } from './Clustering/Clustering';
import { arePositionsClose, findBoxCenter, getViewBounds } from './Clustering/Clustering';

interface CustomBillboardType {
    position: Cartesian3;
    isVisible: boolean;
    data: {
        logitude: number;
        latitude: number;
        id: string;
    };
}

interface ObjectPointProps {
    cesiumWidget: CesiumWidget | null;
}

const findClusters = (
    cesiumWidget: any,
    points: BillboardCollection,
    clusterPoints: PointPrimitiveCollection,
    labels: LabelCollection,
    billboardPositions: CustomBillboardType[],
    lastCameraPosition: Cartesian3,
    lastCameraHeading: number,
) => {
    const { scene } = cesiumWidget;
    const { camera } = scene;

    const currentPosition = camera.position.clone();
    const currentHeading = camera.heading;

    const positionDelta = Cartesian3.magnitude(
        Cartesian3.subtract(currentPosition, lastCameraPosition, new Cartesian3()),
    );
    const headingDelta = Math.abs(currentHeading - lastCameraHeading);

    if (positionDelta < 1000 && headingDelta < 0.01) {
        return;
    }

    lastCameraPosition = currentPosition;
    lastCameraHeading = currentHeading;

    clusterPoints.removeAll();
    labels.removeAll();

    billboardPositions.forEach((billboard, index) => {
        billboard.isVisible = true;
        if (points.get(index)) {
            points.get(index).show = true;
        }
    });

    const clusters: { center: Cartesian3; count: number }[] = [];

    billboardPositions.forEach((billboard, index) => {
        if (!billboard.isVisible) return;

        let count = 1;
        const clusteredPositions: number[] = [index];

        billboardPositions.forEach((otherBillboard, otherIndex) => {
            if (
                index !== otherIndex &&
                otherBillboard.isVisible &&
                arePositionsClose(billboard.position, otherBillboard.position, scene, 120)
            ) {
                count += 1;
                otherBillboard.isVisible = false;
                clusteredPositions.push(otherIndex);
            }
        });

        if (count > 1) {
            let sumX = 0;
            let sumY = 0;
            let sumZ = 0;
            clusteredPositions.forEach((clusteredIndex) => {
                const { position } = billboardPositions[clusteredIndex];
                sumX += position.x;
                sumY += position.y;
                sumZ += position.z;
                if (points.get(clusteredIndex)) {
                    points.get(clusteredIndex).show = false;
                }
            });

            const centerPosition = new Cartesian3(sumX / count, sumY / count, sumZ / count);
            clusters.push({ center: centerPosition, count });
        }
    });

    clusters.forEach((cluster) => {
        const pointSize = 60;

        clusterPoints.add({
            position: cluster.center,
            pixelSize: pointSize,
            color: 'white',
        });

        labels.add({
            position: cluster.center,
            text: cluster.count.toString(),
            font: '16px sans-serif',
            fillColor: Color.BLACK,
            outlineColor: Color.BLACK,
            style: LabelStyle.FILL_AND_OUTLINE,
            verticalOrigin: VerticalOrigin.CENTER,
            horizontalOrigin: HorizontalOrigin.CENTER,
            pixelOffset: new Cartesian2(0, 0),
            scale: 1.0,
            showBackground: true,
            backgroundPadding: new Cartesian2(7, 5),
            backgroundColor: Color.WHITE,
        });
    });
};

export const ObjectPoint = ({ cesiumWidget }: ObjectPointProps) => {
    const [, setViewBounds] = useState<ViewBounds | null>(null);
    const { objects, setSearchBounds } = useDiscoverContext();

    const billboardRef = useRef<BillboardCollection | null>(null);
    const billboardPositionsRef = useRef<CustomBillboardType[]>([]);

    const throttledUpdateBounds = useCallback(
        throttle(() => {
            const bounds = getViewBounds(cesiumWidget);
            if (bounds) {
                setViewBounds(bounds);
                setSearchBounds(bounds as ViewBounds);
            }
        }, 200),
        [cesiumWidget, setViewBounds, setSearchBounds],
    );

    useEffect(() => {
        if (!cesiumWidget) return;

        const updateBoundsImmediately = () => {
            const bounds = getViewBounds(cesiumWidget);
            if (bounds) {
                setViewBounds(bounds);
                setSearchBounds(bounds as ViewBounds);
            }
        };

        cesiumWidget.scene.camera.changed.addEventListener(updateBoundsImmediately);

        return () => {
            if (cesiumWidget) {
                cesiumWidget.scene.camera.changed.removeEventListener(updateBoundsImmediately);
            }
        };
    }, [cesiumWidget, setViewBounds, setSearchBounds]);

    useEffect(() => {
        if (!cesiumWidget) return;

        const updateBoundsImmediately = () => {
            const bounds = getViewBounds(cesiumWidget);
            if (bounds) {
                setViewBounds(bounds);
                setSearchBounds(bounds as ViewBounds);
            }
        };

        cesiumWidget.scene.camera.changed.addEventListener(updateBoundsImmediately);

        return () => {
            if (cesiumWidget) {
                cesiumWidget.scene.camera.changed.removeEventListener(updateBoundsImmediately);
            }
        };
    }, [cesiumWidget, setViewBounds, setSearchBounds]);

    const billboardPositions = useMemo(
        () =>
            objects
                .map((object) => {
                    if (!object.geojson_bounding_box) return null;
                    const position = Cartesian3.fromDegrees(
                        findBoxCenter(object.geojson_bounding_box.coordinates[0]).lng,
                        findBoxCenter(object.geojson_bounding_box.coordinates[0]).lat,
                        0,
                    );
                    return {
                        position,
                        isVisible: true,
                        data: {
                            logitude: findBoxCenter(object.geojson_bounding_box.coordinates[0]).lng,
                            latitude: findBoxCenter(object.geojson_bounding_box.coordinates[0]).lat,
                            id: object.object_id,
                        },
                    };
                })
                .filter(Boolean) as CustomBillboardType[],
        [objects],
    );

    useEffect(() => {
        if (!cesiumWidget) return;

        const points = new BillboardCollection();
        const clusterPoints = new PointPrimitiveCollection();
        const labels = new LabelCollection();

        billboardRef.current = points;
        billboardPositionsRef.current = billboardPositions;

        billboardPositions.forEach((billboard) => {
            points.add({
                position: billboard.position,
                id: billboard.data,
                scale: 0.3,
                image: imagePart,
                verticalOrigin: VerticalOrigin.CENTER,
                horizontalOrigin: HorizontalOrigin.CENTER,
            });
        });

        const lastCameraPosition = new Cartesian3();
        const lastCameraHeading = 0;

        const findClustersCallback = () =>
            findClusters(
                cesiumWidget,
                points,
                clusterPoints,
                labels,
                billboardPositions,
                lastCameraPosition,
                lastCameraHeading,
            );

        cesiumWidget.scene.primitives.add(points);
        cesiumWidget.scene.primitives.add(clusterPoints);
        cesiumWidget.scene.primitives.add(labels);

        cesiumWidget.scene.postRender.addEventListener(findClustersCallback);

        throttledUpdateBounds();

        return () => {
            if (cesiumWidget) {
                cesiumWidget.scene.primitives.remove(points);
                cesiumWidget.scene.primitives.remove(clusterPoints);
                cesiumWidget.scene.primitives.remove(labels);
                cesiumWidget.scene.postRender.removeEventListener(findClustersCallback);
            }
        };
    }, [cesiumWidget, billboardPositions, throttledUpdateBounds]);

    return <div />;
};
