import { getCombinedToken as getAccessToken } from '@local/login';
import { SCALEBAR_OFFSET } from '@local/webviz/dist/components/Scalebar';
import { initializeVisualization } from '@local/webviz/dist/context/createXyzInstanceContext';
import { updateGridlines, updateGridXOffset } from '@local/webviz/dist/context/snapshots/camera';
import type { TokenProvider, XyzInstance } from '@local/webviz/dist/types/xyz';
import Grid from '@mui/material/Grid';
import classnames from 'classnames';
import merge from 'lodash-es/merge';
import { createRef, forwardRef, useCallback, useEffect, useState } from 'react';

import { onDragOver, useDrop } from '../context/hooks/useDragAndDrop';
import { Overlays } from '../Overlays/Overlays';
import { Toolbar } from '../Toolbar/Toolbar';
import { useStyles } from './Plot.styles';
import type { PlotProps } from './Plot.types';

export const tokenProvider: TokenProvider = (url: string | undefined) => {
    // TODO: remove this check or handle it better when points are migrated over to visualization service
    if (url && url.includes('/visualization/orgs/')) {
        return getAccessToken()?.access_token;
    }
    return undefined;
};

export function Plot({ onInitialized, initialized }: PlotProps) {
    const { classes } = useStyles();
    const [htmlElement, setHtmlElement] = useState<HTMLElement | null>(null);
    const [isDraggedOver, setIsDraggedOver] = useState(false);
    const dragRef = createRef<any>();

    useEffect(() => {
        if (!htmlElement) {
            return undefined;
        }
        let instance: XyzInstance | undefined;
        async function initialize(htmlEl: HTMLElement) {
            const initialSnapshot = merge(
                {},
                updateGridlines(false),
                updateGridXOffset(SCALEBAR_OFFSET - 2),
            );

            instance = await initializeVisualization(htmlEl, initialSnapshot, tokenProvider);
            if (instance) {
                onInitialized(instance);
            }
        }
        initialize(htmlElement);
        return () => {
            instance?.destroy();
        };
    }, [htmlElement]);

    function handleDragOver(event: React.DragEvent<HTMLDivElement>) {
        setIsDraggedOver(true);
        onDragOver(event);
    }

    function handleDrop(event: React.DragEvent<HTMLDivElement>) {
        setIsDraggedOver(false);
        dragRef.current(event);
    }

    const handleDragLeave = useCallback(() => setIsDraggedOver(false), []);

    return (
        <Grid container className={classes.root}>
            <Grid
                container
                item
                ref={setHtmlElement}
                className={classnames(classes.plot, { [classes.dragOver]: isDraggedOver })}
                onDrop={handleDrop}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
            >
                {initialized && <Toolbar />}
            </Grid>
            {initialized && <DragHandler ref={dragRef} />}
            {initialized && <Overlays />}
        </Grid>
    );
}

const DragHandler = forwardRef((_: any, ref: any) => {
    /**
     * This component allows us to isolate the drop hook
     * This is necessary so that we may instantiate the xyz instance first,
     * then render any component which _requires_ the instance's API
     */
    const { onDrop } = useDrop();
    // eslint-disable-next-line no-param-reassign
    ref.current = onDrop;
    return null;
});
