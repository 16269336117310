import React, { createContext, useContext, useMemo } from 'react';
import type { SetURLSearchParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

type Context = [searchParams: URLSearchParams, setSearchParams: SetURLSearchParams];
export const SearchParamsContext = createContext<Context>([new URLSearchParams(''), () => {}]);

export const useSearchParamsContext = () => useContext(SearchParamsContext);

interface Props {
    children: React.ReactNode;
}

/**
 * Provides a single instance of useSearchParams from react-router-dom. This prevents multiple instances of the hook clashing when trying to call setSearchParams multiple times in quick succesion
 */
export const SearchParamsProvider = ({ children }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    return (
        <SearchParamsContext.Provider
            value={useMemo(() => [searchParams, setSearchParams], [searchParams, setSearchParams])}
        >
            {children}
        </SearchParamsContext.Provider>
    );
};
