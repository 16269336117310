import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { trackError } from '@local/metrics';
import { EmptyState } from '@local/svgs/dist/pageState';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import type {
    FieldDefinition,
    ListCellComponentProps,
} from '@local/web-design-system/dist/components/GenericListing';
import { ListItem } from '@local/web-design-system/dist/components/GenericListing';
import { SortedList } from '@local/web-design-system/dist/components/PaginatedList';
import type { SelectMenuItem } from '@local/web-design-system/dist/components/SelectMenu';
import { SelectMenu } from '@local/web-design-system/dist/components/SelectMenu';
import { TableLoadingSkeleton } from '@local/web-design-system/dist/components/TableLoadingSkeleton/TableLoadingSkeleton';
import { useTrace } from '@local/web-design-system/dist/utils';
import {
    useAssignUserRoleAdminMutation,
    useListUserWorkspacesAdminQuery,
} from '@local/workspaces/dist/apiClients/enhancedWorkspaceMiddleware';
import type {
    RoleEnum,
    UserWorkspaceResponse,
} from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import {
    getHubForCurrentOrg,
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { createRoleOption } from '@local/workspaces/dist/pages/inviteUsersPage/components/utils';
import { FAILED_TO_EDIT_USERS } from '@local/workspaces/dist/strings';
import { defaultStringSort } from '@local/workspaces/dist/utils/Sorting';
import { PERMISSIONS_USERS_PAGE } from '@local/workspaces/src/urls';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, useParams } from 'react-router-dom';

import { USER_NO_WORKSPACES, USER_NO_WORKSPACES_MESSAGE } from '../../../strings';
import { useStyles } from '../EditUser.styles';
import { WorkspaceActions } from './RemoveFromWorkspace';
import { WorkspaceRoleAssigner } from './WorkspaceRoleAssigner';

export function UsersWorkspaces() {
    const params = useParams();
    const { classes } = useStyles();

    const userId = params.userUuid;

    const { data, isFetching, isError } = useListUserWorkspacesAdminQuery({
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
        // This should never be null as the page will redirect you before getting here
        userId: params.userUuid || '',
        sort: 'name',
        limit: 9999999999,
    });

    return (
        <div className={classes.mainContent}>
            <Grid className={classes.list}>
                <SortedList
                    hasActions
                    isError={!isFetching && isError}
                    stickyHeader
                    emptyMessage={
                        <div>
                            <EmptyState
                                title={USER_NO_WORKSPACES}
                                message={USER_NO_WORKSPACES_MESSAGE}
                                image={<NotFoundSvg />}
                            />
                        </div>
                    }
                    isEmpty={!isFetching && data?.results.length === 0}
                    headersOnEmptyMessage
                    itemDef={itemDef}
                    postHeader={<WorkspaceRoleAssigner workspaces={data?.results} />}
                >
                    {isFetching || !userId ? (
                        <TableLoadingSkeleton rows={3} columns={2} hasActions />
                    ) : (
                        data?.results.map((workspace) => (
                            <SortedWorkspaceItem
                                key={workspace.id}
                                userId={userId}
                                workspace={workspace}
                                sortKey={workspace.id}
                            />
                        ))
                    )}
                </SortedList>
            </Grid>
        </div>
    );
}

const WORKSPACE_TABLE_FIELDS = {
    name: 'Workspaces',
    permissions: 'User Permissions',
    role: 'Role',
};

const WorkspaceLinkCell = ({ item, itemKey, classes }: ListCellComponentProps) => {
    const hubCode = getHubForCurrentOrg().code;

    return (
        <Link to={`../../workspaces/${hubCode}/${item.id}/${PERMISSIONS_USERS_PAGE}`}>
            <Typography variant="body2" color="secondary" className={classes.linkText}>
                {item[itemKey]}
            </Typography>
        </Link>
    );
};

const roleOptions: SelectMenuItem[] = [
    createRoleOption('viewer'),
    createRoleOption('editor'),
    createRoleOption('owner'),
];

const WorkspaceRoleCell = ({ item, itemKey }: ListCellComponentProps) => {
    const applyTrace = useTrace('role-assigner');
    const [roleAssignerAdmin] = useAssignUserRoleAdminMutation();
    const hubUrl = getHubUrlForCurrentOrg();
    const params = useParams();
    const { addMessage } = useMessagesContext();

    const onChange = async (selectedRole: string | number) => {
        try {
            await roleAssignerAdmin({
                workspaceId: item.id,
                orgId: getOrgUuidFromParams(params),
                hubUrl,
                assignRoleRequest: {
                    role: selectedRole as RoleEnum,
                    user_id: item.user_id,
                },
            }).unwrap();
        } catch (error) {
            trackError('Error updating user role', JSON.stringify(error));
            addMessage({
                message: FAILED_TO_EDIT_USERS,
                severity: 'error',
            });
        }
    };

    return (
        <Grid item>
            <SelectMenu
                onSelect={onChange}
                options={roleOptions}
                selected={item[itemKey]}
                setWidth={119}
                {...applyTrace('menu')}
            />
        </Grid>
    );
};

const itemDef: FieldDefinition[] = [
    {
        key: 'name',
        label: WORKSPACE_TABLE_FIELDS.name,
        component: WorkspaceLinkCell,
        sortFunction: defaultStringSort,
    },
    {
        key: 'user_role',
        label: WORKSPACE_TABLE_FIELDS.permissions,
        component: WorkspaceRoleCell,
        sortFunction: defaultStringSort,
    },
];

interface SortedWorkspaceItemProps {
    userId: string;
    workspace: UserWorkspaceResponse;
    registerForSorting?: (sortFieldsFunc: () => UserWorkspaceResponse) => void;
    // eslint-disable-next-line react/no-unused-prop-types
    sortKey: string;
}

function SortedWorkspaceItem(props: SortedWorkspaceItemProps) {
    const { workspace, userId, registerForSorting = () => null } = props;

    registerForSorting(() => workspace);

    return (
        <ListItem
            key={workspace.id}
            item={{ ...workspace, user_id: userId }}
            fields={itemDef}
            actions={<WorkspaceActions workspace={workspace} />}
        />
    );
}
