import { KeyValueInfoTable } from '@local/web-design-system-2/dist/components/KeyValueInfoTable/KeyValueInfoTable';
import type { Folder } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { FILTER_DESCRIPTION, FILTER_NAME, FILTER_PROPERTIES } from 'src/strings';

interface Props {
    data?: Folder;
}

export const FilterProperties = ({ data }: Props) => (
    <Stack spacing={2}>
        <Typography variant="h6">{FILTER_PROPERTIES}</Typography>
        <Divider />
        <Stack>
            <KeyValueInfoTable
                items={[
                    {
                        label: FILTER_NAME,
                        safeToLogLabel: true,
                        value: data?.name,
                    },
                    {
                        label: FILTER_DESCRIPTION,
                        safeToLogLabel: true,
                        value: data?.description,
                    },
                ]}
            />
        </Stack>
    </Stack>
);
