import type { OrgListedObject } from '@api/goose/dist/enhancedGooseClient';
import * as L from 'leaflet';
import { useEffect } from 'react';
import { GeoJSON, useMap } from 'react-leaflet';

import { FLY_TO_BOUNDS_DURATION, FLY_TO_BOUNDS_PADDING } from 'src/constants';

interface Props {
    object?: OrgListedObject;
    flyToBounds?: boolean;
}

export function ObjectBounds({ object, flyToBounds }: Props) {
    const map = useMap();

    useEffect(() => {
        if (flyToBounds && object && object.geojson_bounding_box) {
            // GOOSE API returns lng,lat, but Leaflet requires lat,lng
            const bboxLatLong: L.LatLngTuple[] = object.geojson_bounding_box.coordinates[0].map(
                (lngLat) => [lngLat[1], lngLat[0]],
            );
            const latLongBounds = new L.LatLngBounds(bboxLatLong);
            map.flyToBounds(latLongBounds, {
                duration: FLY_TO_BOUNDS_DURATION,
                padding: [FLY_TO_BOUNDS_PADDING, FLY_TO_BOUNDS_PADDING],
            });
        }
    }, [object?.object_id]);

    if (!object || !object.geojson_bounding_box) {
        return null;
    }
    return (
        <GeoJSON
            style={{ weight: 1 }}
            key={object.object_id}
            data={object.geojson_bounding_box as any}
        />
    );
}
