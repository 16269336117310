import type { OrgListedObject } from '@api/goose/dist/enhancedGooseClient';
import { Visibility, VisibilityOff } from '@local/web-design-system-2/dist/icons';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { useState } from 'react';
import { FormattedDate } from 'react-intl';

import { OverflowTooltip } from '../../../components/overflowTooltip/OverflowTooltip';
import { formatObjectName } from '../../../utils/objectUtils';
import { SchemaIcon } from '../../workspacePage/workspaceContent/SchemaIcon';
import { useStyles, useToolTipStyles } from './ResultsPanel.styles';

// ========== Util Components ==========

interface ObjectIconProps {
    schema: string;
}

export function ObjectIcon({ schema }: ObjectIconProps) {
    const { classes } = useStyles();
    const Icon = SchemaIcon(schema);
    return <Icon className={classes.objectIcon} />;
}

interface ObjectVisibilityIconProps {
    isHovering: boolean;
    isSelected: boolean;
}

function ObjectVisibilityIcon({ isHovering, isSelected }: ObjectVisibilityIconProps) {
    const { classes } = useStyles();

    if (isHovering && isSelected) {
        return (
            <VisibilityOff
                className={classnames(classes.visibilityIcon, classes.visibilityIconEnabled)}
            />
        );
    }

    if (!isHovering && !isSelected) {
        return <VisibilityOff className={classes.visibilityIcon} />;
    }

    return (
        <Visibility className={classnames(classes.visibilityIcon, classes.visibilityIconEnabled)} />
    );
}

// ========== Result Row ==========

interface ResultRowProps {
    activeObjectID: string;
    object: OrgListedObject;
    onClick: (objectID: string) => void;
    onHover: (objectID: string, isHovering: boolean) => void;
}

export function ResultRow({ object, onClick, activeObjectID, onHover }: ResultRowProps) {
    const { classes } = useStyles();
    const { classes: toolTipClasses } = useToolTipStyles();
    const isSelected = object.object_id === activeObjectID;
    const [isHovered, setIsHovered] = useState(false);

    const hoverChange = (isHovering: boolean) => {
        setIsHovered(isHovering);
        onHover(object.object_id, isHovering);
    };

    return (
        <Grid
            item
            gap={2}
            container
            wrap="nowrap"
            direction="row"
            alignItems="center"
            key={object.object_id}
            onClick={() => onClick?.(object.object_id)}
            onMouseEnter={() => hoverChange(true)}
            onMouseLeave={() => hoverChange(false)}
            className={classnames(classes.objectRow, {
                [classes.objectRowSelected]: isSelected,
            })}
        >
            <IconButton classes={{ root: classes.iconButton }}>
                <ObjectVisibilityIcon isHovering={isHovered} isSelected={isSelected} />
            </IconButton>
            <Divider orientation="vertical" className={classes.rowDivider} />
            <div className={classes.spacer} />
            <ObjectIcon schema={object.schema} />
            <Grid item container wrap="nowrap" flexGrow={1} direction="row" overflow="hidden">
                <Grid container direction="column">
                    <OverflowTooltip
                        arrow
                        placement="top-start"
                        classes={toolTipClasses}
                        title={formatObjectName(object.name)}
                    >
                        <Typography noWrap width="100%" className={classes.rowTitleText}>
                            {formatObjectName(object.name)}
                        </Typography>
                    </OverflowTooltip>
                    <Grid
                        item
                        container
                        direction="row"
                        gap={1}
                        alignItems="center"
                        className={classes.rowSubtitleText}
                    >
                        <Typography className={classes.rowSubtitleText}>
                            {object?.workspace_name ?? 'Unknown Workspace'}
                        </Typography>
                        <Divider orientation="vertical" className={classes.textDivider} />
                        <FormattedDate value={object.created_at} month="short" year="numeric" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
