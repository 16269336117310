import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { trackError } from '@local/metrics/dist/src/metrics';
import MultiValueSelect from '@local/web-design-system/dist/components/MultiValueInput/MultiValueSelect';
import type { SelectMenuItem } from '@local/web-design-system/dist/components/SelectMenu';
import { SelectMenu } from '@local/web-design-system/dist/components/SelectMenu/SelectMenu';
import { Skeleton } from '@local/web-design-system/dist/components/Skeleton/Skeleton';
import { useTrace } from '@local/web-design-system/dist/utils';
import type {
    RoleEnum,
    UserWorkspaceResponse,
} from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import { useBulkAssignRolesAdminMutation } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import { fetchWorkspaces } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import {
    getHubUrlForCurrentOrg,
    getOrgUuidFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { createRoleOption } from '@local/workspaces/dist/pages/inviteUsersPage/components/utils';
import Button from '@mui/material/Button/Button';
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    ASSIGN_WORKSPACES,
    ENTER_WORKSPACE,
    FAILED_TO_ADD_WORKSPACES,
    FAILED_TO_LOAD_WORKSPACES,
    NO_WORKSPACES_FOUND,
} from 'src/strings';

import { useStyles } from './WorkspaceRoleAssigner.styles';

export function WorkspaceRoleAssigner({
    workspaces,
}: {
    workspaces: UserWorkspaceResponse[] | undefined;
}) {
    const applyTrace = useTrace('role-assigner');
    const params = useParams();
    const currentUserUuid = params.userUuid ?? '';
    const { addMessage } = useMessagesContext();

    const { classes } = useStyles();

    const [searchActive, setSearchActive] = useState(false);
    const [selectedWorkspaceList, setSelectedWorkspaceList] = useState([]);
    const [selectedRole, setSelectedRole] = useState<RoleEnum>('viewer');

    const [roleAssignerAdmin, { isLoading }] = useBulkAssignRolesAdminMutation();

    const roleOptions: SelectMenuItem[] = [
        createRoleOption('viewer'),
        createRoleOption('editor'),
        createRoleOption('owner'),
    ];

    const onChange = (selectedOptions: any) => {
        const selectedWorkspaces = selectedOptions.map((workspace: any) => workspace.id);
        setSearchActive(selectedWorkspaces.length !== 0);
        setSelectedWorkspaceList(selectedWorkspaces);
    };

    const onSubmit = async () => {
        try {
            roleAssignerAdmin({
                orgId: getOrgUuidFromParams(params),
                hubUrl: getHubUrlForCurrentOrg(),
                bulkUserRoleAssignmentsRequest: {
                    role_assignments: selectedWorkspaceList.map((workspaceId) => ({
                        workspace_id: workspaceId,
                        user_id: currentUserUuid,
                        role: selectedRole,
                    })),
                },
            }).unwrap();

            // reset selection
            setSelectedWorkspaceList([]);
            setSearchActive(false);
        } catch (assignError) {
            trackError('Error adding user to workspace', JSON.stringify(assignError));
            addMessage({
                message: FAILED_TO_ADD_WORKSPACES,
                severity: 'error',
            });
        }
    };

    const { data, isFetching, isError, error } = fetchWorkspaces({
        isAdmin: true,
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: getOrgUuidFromParams(params),
        limit: 9999999999,
        sort: 'name',
    });

    useEffect(() => {
        // this is in a useEffect to make react happy
        if (isError) {
            addMessage({
                message: FAILED_TO_LOAD_WORKSPACES,
                severity: 'error',
            });
            trackError('Unable to load workspace list', 'Request to idp failed', { error });
        }
    }, [isError]);

    if (isError) {
        return null;
    }

    if (isFetching) {
        return (
            <TableRow key="postHeader1" className={classes.root}>
                <TableCell colSpan={1} key={1} classes={classes}>
                    <Skeleton variant="rectangle" height="60px" />
                </TableCell>
                <TableCell key={2} classes={classes}>
                    <Skeleton variant="rectangle" height="60px" />
                </TableCell>
                <TableCell key={3} classes={classes}>
                    <Skeleton variant="rectangle" height="60px" width="150px" />
                </TableCell>
            </TableRow>
        );
    }

    const workspaceIds = workspaces?.map((workspace) => workspace.id);
    const filteredData = data?.results.filter((workspace) => !workspaceIds?.includes(workspace.id));

    return (
        <TableRow key="postHeader1" className={classes.root}>
            <TableCell colSpan={1} style={{ paddingRight: 0 }} className={classes.searchColumn}>
                <div className={classes.wideSearch}>
                    <MultiValueSelect
                        placeholder={ENTER_WORKSPACE}
                        noOptionsMessage={() => NO_WORKSPACES_FOUND}
                        getOptionLabel={(workspace: any) => workspace.name}
                        getOptionValue={(workspace: any) => workspace.id}
                        options={filteredData ?? []}
                        onChange={onChange}
                        value={searchActive ? undefined : ''}
                        {...applyTrace('search')}
                        classNames={{
                            menuList: () => classes.menuList,
                        }}
                    />
                </div>
            </TableCell>
            <TableCell className={classes.column}>
                <Grid item xs={6} container>
                    <div className={classes.selector}>
                        <SelectMenu
                            onSelect={(role) => {
                                setSelectedRole(role as RoleEnum);
                            }}
                            options={roleOptions}
                            selected={selectedRole}
                            {...applyTrace('menu')}
                        />
                    </div>
                </Grid>
            </TableCell>
            <TableCell className={classes.column}>
                <Grid container justifyContent="flex-end" item className={classes.button}>
                    {isLoading ? (
                        <Skeleton height={40} width={152} variant="rectangle" />
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!searchActive}
                            onClick={onSubmit}
                            style={!searchActive ? { pointerEvents: 'none' } : {}}
                            className={classes.workspaceButton}
                            {...applyTrace('btn-assign')}
                        >
                            {ASSIGN_WORKSPACES}
                        </Button>
                    )}
                </Grid>
            </TableCell>
        </TableRow>
    );
}
