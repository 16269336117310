import type { OrgListedObject } from '@api/goose/dist/enhancedGooseClient';
import { useListObjectsByOrgQuery } from '@api/goose/dist/enhancedGooseClient';
import type { BoundingBoxCoords } from '@local/map-viewer/dist/types';
import { capBounds } from '@local/map-viewer/dist/utils/capBounds';
import { getGeoJSONBoundingBox } from '@local/map-viewer/dist/utils/getGeoJSONBoundingBox';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

interface Context {
    isLoading: boolean;
    isFetching: boolean;
    objects: OrgListedObject[];
    searchQuery: string;
    activeObject: OrgListedObject | null;
    setSearchQuery: (query: string) => void;
    setActiveObject: (objectID: string) => void;
    toggleActiveObject: (objectID: string) => void;
    setSearchBounds: (bounds: BoundingBoxCoords | null) => void;
}

export const DiscoverContext = createContext<Context>({
    objects: [],
    searchQuery: '',
    isLoading: false,
    isFetching: false,
    activeObject: null,
    setSearchQuery: () => {},
    setActiveObject: () => {},
    setSearchBounds: () => {},
    toggleActiveObject: () => {},
});

export const useDiscoverContext = () => useContext(DiscoverContext);

interface Props {
    children: React.ReactNode;
}

export const DiscoverProvider = ({ children }: Props) => {
    const { orgUuid } = useParams();
    const [searchQuery, setSearchQuery] = useState('');
    const [activeObjectID, setActiveObject] = useState('');
    const [objects, setObjects] = useState<OrgListedObject[]>([]);
    const [bounds, setSearchBounds] = useState<BoundingBoxCoords | null>(null);

    const queryBounds = useMemo(
        () => (bounds ? getGeoJSONBoundingBox(capBounds(bounds)) : null),
        [bounds],
    );

    const { data, isLoading, isFetching } = useListObjectsByOrgQuery({
        orgId: orgUuid ?? '',
        geojsonBoundingBox: queryBounds ? [queryBounds] : undefined,
        permittedWorkspacesOnly: true,
    });

    useEffect(() => {
        if (!data?.objects || isLoading || isFetching) {
            return;
        }
        setObjects(data.objects);
    }, [data?.objects, isLoading, isFetching]);

    useEffect(() => {
        const object = objects.find((obj: OrgListedObject) => obj.object_id === activeObjectID);
        if (!object) {
            setActiveObject('');
        }
    }, [activeObjectID, objects, setActiveObject]);

    const toggleActiveObject = useCallback(
        (objectID?: string) => {
            setActiveObject((prevID) => (prevID === objectID ? '' : (objectID ?? '')));
        },
        [setActiveObject],
    );

    return (
        <DiscoverContext.Provider
            value={useMemo(
                () => ({
                    objects,
                    isLoading,
                    isFetching,
                    searchQuery,
                    setSearchQuery,
                    setSearchBounds,
                    setActiveObject,
                    toggleActiveObject,
                    activeObject:
                        objects.find((obj: OrgListedObject) => obj.object_id === activeObjectID) ??
                        null,
                }),
                [
                    objects,
                    isLoading,
                    isFetching,
                    searchQuery,
                    activeObjectID,
                    setSearchQuery,
                    setSearchBounds,
                    setActiveObject,
                    toggleActiveObject,
                ],
            )}
        >
            {children}
        </DiscoverContext.Provider>
    );
};

export function withDiscoverContext(child: React.ReactNode) {
    return <DiscoverProvider>{child}</DiscoverProvider>;
}
